<template>
  <jet-card class="product-funciton" title="产品功能" :titleType="2">
    <div class="container">
      <ul class="product-list">
        <li>
          <a-row type="flex" justify="space-between">
            <a-col :span="16">
              <h3>多数据源接入</h3>
              <p>支持API、WebSocket、静态数据（Excel/Json）、数据库（MySQL/PostgreSQL/Oracle/MongoDB）数据源连接；可实现多源数据交互。</p>
            </a-col>
            <a-col :span="8">
              <div class="img">
                <img src="@/assets/images/production/view/product1.png">
              </div>
            </a-col>
          </a-row>
        </li>
        <li>
          <a-row type="flex" justify="space-between">
            <a-col :span="16">
              <h3>拖拽式编辑</h3>
              <p>支持自由拖拽布局，并且适配多端预览。</p>
            </a-col>
            <a-col :span="8">
              <div class="img">
                <img src="@/assets/images/production/view/product2.png">
              </div>
            </a-col>
          </a-row>
        </li>
        <li>
          <a-row type="flex" justify="space-between">
            <a-col :span="16">
              <h3>3D可视化</h3>
              <p>基于threeJS，支持glb、gltf等格式模型上传、渲染；支持数据看板配置以及数据交互事件动画。</p>
            </a-col>
            <a-col :span="8">
              <div class="img">
                <img src="@/assets/images/production/view/product3.png">
              </div>
            </a-col>
          </a-row>
        </li>
        <li>
          <a-row type="flex" justify="space-between">
            <a-col :span="16">
              <h3>图元组件丰富</h3>
              <p>支持30+种图元组件,以及800+的2d组态素材；基于ECharts支持自定义图表拓展，可满足个性化需求。</p>
            </a-col>
            <a-col :span="8">
              <div class="img">
                <img src="@/assets/images/production/view/product4.png">
              </div>
            </a-col>
          </a-row>
        </li>
        <li>
          <a-row type="flex" justify="space-between">
            <a-col :span="16">
              <h3>导入导出</h3>
              <p>项目导入、导出和复制功能管理，更为便捷使用、部署。</p>
            </a-col>
            <a-col :span="8">
              <div class="img">
                <img src="@/assets/images/production/view/product5.png">
              </div>
            </a-col>
          </a-row>
        </li>
        <li>
          <a-row type="flex" justify="space-between">
            <a-col :span="16">
              <h3>灵活发布</h3>
              <p>灵活发布管理，提供三方嵌入，支持免登录、授权认证。</p>
            </a-col>
            <a-col :span="8">
              <div class="img">
                <img src="@/assets/images/production/view/product6.png">
              </div>
            </a-col>
          </a-row>
        </li>
      </ul>
    </div>
  </jet-card>
</template>

<script>
export default {
  name: '',
  data() {
    return {

    }
  },
  components: {

  },
  methods: {

  },
}
</script>
<style scoped lang="less">
.product-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  li {
    width: 48%;
    height: 14.25rem;
    margin-top: 1.5rem;
    border-radius: 16px;
    box-shadow: 0px 0px 8px rgba(50, 50, 71, 0.05);
    padding: 2.625rem 1.5rem;

    p {
      color: rgba(11, 17, 69, 0.8);
      font-size: 1.125rem;
    }

    .img {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>