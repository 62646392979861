<template>
  <div class="jetlinks-iot">
    <Banner title="JetLinks VIEW" subTitle="物联网可视化平台" :label="['集可视化大屏、组态、3D数字孪生和报表为一体的强大编辑工具，组件丰富，开箱即用所见即所得，无需任何编码。']">
      <img src="@/assets/images/production/view.png">
    </Banner>
    <ProductFunction></ProductFunction>
    <Solution></Solution>
    <Advantage></Advantage>
    <!-- <ProductUser></ProductUser> -->
  </div>
</template>

<script>
import Banner from '@/components/production/Banner';
import ProductFunction from '@/components/production/jetlinksview/ProductFunction';
import Solution from '@/components/production/jetlinksview/Solution';
import Advantage from '@/components/production/jetlinksview/Advantage';
import ProductUser from '@/components/production/jetlinksview/ProductUser';
export default {
  name: '',
  data() {
    return {
       
    }
  },
  components: {
    Banner,
    ProductFunction,
    Solution,
    Advantage,
    ProductUser
  },
  methods: {
    
  },
}
</script>
<style scoped>
  
</style>