<template>
  <jet-card class="solution" title="行业解决方案" :titleType="2" bgColor="#F5F8FE">
    <a-tabs default-active-key="1" @change="change">
      <a-tab-pane key="1" tab="智慧工业">
        <div class="img-list">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide>
              <img src="@/assets/images/production/view/solution1.png" alt="">
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/images/production/view/solution2.png" alt="">
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/images/production/view/solution3.png" alt="">
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/images/production/view/solution4.png" alt="">
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/images/production/view/solution5.png" alt="">
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="智慧园区">
        <div class="img-list">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide>
              <img src="@/assets/images/production/view/solution6.png" alt="">
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/images/production/view/solution7.png" alt="">
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </a-tab-pane>
      <a-tab-pane key="3" tab="2D组态">
        <div class="img-list">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide>
              <img src="@/assets/images/production/view/solution8.gif" alt="">
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/images/production/view/solution9.gif" alt="">
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/images/production/view/solution10.gif" alt="">
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/images/production/view/solution11.gif" alt="">
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/images/production/view/solution12.gif" alt="">
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </a-tab-pane>
      <a-tab-pane key="4" tab="智慧消防">
        <div class="img-list">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide>
              <img src="@/assets/images/production/view/solution13.png" alt="">
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/images/production/view/solution14.png" alt="">
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </a-tab-pane>
      <a-tab-pane key="5" tab="智慧楼宇">
        <div class="img-list">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide>
              <img src="@/assets/images/production/view/solution15.png" alt="">
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/images/production/view/solution16.png" alt="">
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/images/production/view/solution17.png" alt="">
            </swiper-slide>
            <swiper-slide>
              <img src="@/assets/images/production/view/solution18.png" alt="">
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </a-tab-pane>
      <a-tab-pane key="6" tab="3D可视化">
        <div class="img-list">
          <swiper class="swiper one" :options="swiperOption">
            <swiper-slide>
              <img src="@/assets/images/production/view/solution19.png" alt="">
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </a-tab-pane>
    </a-tabs>
  </jet-card>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
export default {
  name: '',
  data() {
    return {
      swiperOption: { //swiper组件配置
        slidesPerView: 2, //一次显示多少个
        spaceBetween: 50,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    change() {

    }
  },
}
</script>
<style scoped lang="less">
.solution {

  .img-list {
    display: flex;
    justify-content: space-between;

    .swiper {
      width: 100%;
      height: 348px;
      &.one{
        :deep(.swiper-wrapper) {
          justify-content: center;
        }
      }
      .swiper-slide {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.75rem;
        img{
          width: 100%;
          height: auto;
        }
      }

      // 修改分页器的样式
      .swiper-pagination {
        position: absolute !important;

        .swiper-pagination-bullet {
          border-radius: 0px !important;
        }
      }
    }
  }
}

.ant-tabs {
  margin-top: 2rem;
}

:deep(.ant-tabs-tab) {
  font-size: 1.5rem;
  font-weight: 500;
  color: #0B1145;
}

:deep(.ant-tabs-nav .ant-tabs-tab-active) {
  color: #0E6EFF;
}
</style>