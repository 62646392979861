<template>
  <jet-card class="product-user" title="产品用户" :titleType="2" bgColor="#F5F8FE">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide>
        <img src="@/assets/images/production/view/partner1.png">
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/images/production/view/partner2.png">
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/images/production/view/partner3.png">
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/images/production/view/partner4.png">
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/images/production/view/partner5.png">
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/images/production/view/partner6.png">
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/images/production/view/partner7.png">
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/images/production/view/partner8.png">
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/images/production/view/partner9.png">
      </swiper-slide>
      <swiper-slide>
        <img src="@/assets/images/production/view/partner10.png">
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
  </jet-card>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
export default {
  name: '',
  data() {
    return {
      swiperOption: { //swiper组件配置
        slidesPerView: 5, //一次显示多少个
        spaceBetween: 40,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        slidesPerColumn: 2,
        slidesPerColumnFill: 'row',
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    
  },
}
</script>
<style scoped lang="less">
  .swiper {
    .swiper-slide {
      margin-top: 3rem;
      width: 13.375rem;
      height: 7.8125rem;
      background-color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.75rem;
      box-shadow: 0px 10px 16px rgba(43, 47, 50, 0.05);
    }

    // 修改分页器的样式
    .swiper-pagination {
      position: static !important;
      margin-top: 3rem;

      .swiper-pagination-bullet {
        border-radius: 0px !important;
      }
    }
  }
</style>