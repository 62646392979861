<template>
  <div class="banner">
    <div class="banner-left">
      <h1>{{title}}</h1>
      <h3>{{subTitle}}</h3>
      <p class="content" v-for="item, index in label" :key="index">{{item}}</p>
      <div class="btns">
        <a-button type="primary" class="radius" style="margin-right: 1rem;">
          <a :href="$route.path == '/production/view' ? 'https://view.jetlinks.cn/' : 'https://demo.jetlinks.cn/'" target="_blank">开始使用</a>
          <!-- <a href="https://view.jetlinks.cn/" target="_blank" >开始使用</a> -->
        </a-button>
        <a-button type="primary" ghost class="radius">
          <router-link to="/contact_us">联系我们</router-link>
        </a-button>
      </div>
    </div>
    <div class="banner-right">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
       
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    label: {
      type: Array,
      default: []
    }
  },
  components: {
    
  },
  methods: {
    
  },
}
</script>
<style scoped lang="less">
  .banner{
    height: 43.75rem;
    padding: 10rem 0 10rem 25rem;
    background: url('~assets/images/production/banner-bg1.png') no-repeat center center/100% 100%;
    display: flex;
    .banner-left {
      width: 39.875rem;
      margin-right: 14.375rem;
      h1{
        font-size: 4rem;
        font-weight: 600;
        letter-spacing: 5px;
      }
      h3{
        font-size: 2.5rem;
        font-weight: 500;
        letter-spacing: 5px;
        color: #0E6EFF;
      }
      p{
        font-size: 1.125rem;
        font-weight: 400;
        letter-spacing: 5px;
        color: rgba(11, 17, 69, 0.8);;
        padding: 1rem 1rem 1rem 0;
      }
    }
  }
</style>