<template>
  <jet-card class="advantage" title="产品优势" :titlType="2">
    <ul class="card-list">
      <li class="card-list-item">
        <h4 class="title">自主研发</h4>
        <p class="content">采用响应式框架，基于Java8,Spring Boot2.x,WebFlux,Netty,Vert.x,Reactor等开发</p>
        <img src="@/assets/images/production/view/adv1.png" class="symbol">
      </li>
      <li class="card-list-item">
        <p class="title">通用性强</p>
        <p class="content">泛型可视化平台，适用于多种垂直行业场景应用，同时剥离各个场景下的通用需求，不断在产品层面上丰富扩充。</p>
        <img src="@/assets/images/production/view/adv2.png" class="symbol">
      </li>
      <li class="card-list-item">
        <p class="title">功能强大</p>
        <p class="content">为系统项目提供项目管理、可视化处理、数据收集、处理以及可视化整体框架。</p>
        <img src="@/assets/images/production/view/adv3.png" class="symbol">
      </li>
      <li class="card-list-item">
        <p class="title">授权方式灵活</p>
        <p class="content">为系统项目提供项目管理、可视化处理、数据收集、处理以及可视化整体框架。</p>
        <img src="@/assets/images/production/view/adv4.png" class="symbol">
      </li>
    </ul>
  </jet-card>
</template>

<script>
export default {
  name: '',
  data() {
    return {

    }
  },
  components: {

  },
  methods: {

  },
}
</script>
<style scoped lang="less">
   .card-list{
    width: 75rem;
    margin: 0 auto;
    display: flex;
    .card-list-item {
      width: 16.25rem;
      margin-right: 2rem;
      background-color: #FFFFFF;
      box-sizing: border-box;
      text-align: center;
      border: 1px solid rgba(30, 60, 96, 0);
      padding: 0 2.25rem;
      color: #000000;
      &:hover{
        border: 1px solid rgba(30, 60, 96, 0.1);
        box-shadow: 0px 4px 10px rgba(20, 54, 94, 0.1);
        border-radius: 12px;
      }
      .symbol {
        margin: 0 auto;
      }
      .title {
        margin-top: 20px;
        font-weight: 700;
        font-size: 1.5rem;
        color: #061437;
      }
      .content {
        font-size: 1rem;
        margin: 0;
        text-align: left;
        color: rgba(6, 20, 55, 0.6);
      }
    }
  }
</style>